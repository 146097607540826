<!-- =========================================================================================
  File Name: EditCourse.vue
  Description: User Edit Page

========================================================================================== -->

<template>
  <div id="page-user-edit">
    <vs-alert color="danger" title="Lab Not Found" :active.sync="errorOccurred">
      <span>Course record with id: {{ $route.params.labId }} not found. </span>
      <span> <span>Check </span><router-link :to="{ name: 'admin-list-lab' }" class="text-inherit underline">All Labs</router-link> </span>
    </vs-alert>

    <vx-card v-if="lab_data">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Details" icon-pack="feather" icon="icon-edit">
            <labs-details-tab class="mt-4" :data="lab_data" v-on:update_lab_data="update_lab_data" :key="refresh_key" />
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import LabsDetailsTab from './LabsDetailsTab';

export default {
  components: {
    LabsDetailsTab,
  },
  data() {
    return {
      lab_data: null,
      errorOccurred: false,

      refresh_key: 0,

      difficulties: [
        { id: '0', label: 'Simple' },
        { id: '1', label: 'Easy' },
        { id: '2', label: 'Moderate' },
        { id: '3', label: 'Hard' },
        { id: '4', label: 'Challenging' },
      ],

      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      this.load_lab_data(this.$route.params.labId);
    },
  },
  methods: {
    load_lab_data() {
      this.$http
        .get(`lab/activity/${this.$route.params.labId}`)
        .then((response) => {
          if (response.status === 200) {
            this.lab_data = response.data;
            this.refresh_key++;
          }
        })
        .catch((error) => {
          this.errorOccurred = true;
          this.error_msg = error;
        });
    },
    update_lab_data(data) {
      const pl = data;
      pl.status = data.status === undefined ? 'active' : data.status;

      if (pl.name === undefined) {
        return this.$vs.notify({
          title: 'Failed to update lab',
          text: 'Please provide a Lab Name',
          color: 'danger',
          position: 'top-right',
        });
      }

      this.$vs.loading();

      this.$http
        .patch(`lab/activity/${this.$route.params.labId}`, pl)
        .then((response) => {
          if (response.status === 200) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Updated Lab Successfully',
              text: `Lab ${pl.name} has been updated.`,
              color: 'success',
              position: 'top-right',
            });
            this.load_lab_data(this.$route.params.labId);
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while updating this lab';
          if (exception.response) {
            error = exception.response.data.error.description;
          }

          return this.$vs.notify({
            title: 'Failed to update lab',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
  created() {
    this.load_lab_data(this.$route.params.labId);
  },
};
</script>
