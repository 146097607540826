<template>
  <div id="course-create-details-tab" @keyup.ctrl.enter="lab_update_data">
    <div class="vx-col w-full">
      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/3 w-full mb-2">
          <vs-input v-model="dataLocal.name" class="w-full" label-placeholder="Lab Name" />
        </div>
        <div class="vx-col sm:w-1/3 w-full mb-2">
          <vs-select class="w-full mt-5" placeholder="Lab Status" v-model="dataLocal.status">
            <vs-select-item :key="index" :value="option" :text="option | capitalize" v-for="(option, index) in status_options" />
          </vs-select>
        </div>
        <!-- <div class="vx-col sm:w-1/3 w-full mb-2">
          <vs-select class="w-full mt-5" placeholder="Lab Difficulty" v-model="dataLocal.difficulty">
            <vs-select-item :key="index" :value="option" :text="option" v-for="(option, index) in difficulties" />
          </vs-select>
        </div> -->
      </div>
      <div class="vx-row mb-4">
        <div class="vx-col w-full">
          <vs-input v-model="dataLocal.thumbnail" class="w-full" label-placeholder="Thumbnail URL" />
        </div>
      </div>
      <div class="vx-row mb-4">
        <div class="vx-col w-full">
          <vs-input v-model="dataLocal.explanationVideo" class="w-full" label-placeholder="Explanation Video URL" />
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col w-1/3">
          <vs-input v-model="dataLocal.duration" class="w-full" label-placeholder="Lab Duration" />
        </div>
        <div class="vx-col w-1/3">
          <vs-input v-model="dataLocal.labs_activity_id" class="w-full" label-placeholder="Lab Activity ID" />
        </div>
        <div class="vx-col w-1/3">
          <p class="text-sm">Lab Pool ID</p>
          <v-select placeholder="Lab Pool ID" :options="pools" v-model="dataLocal.labs_pool_id" class="mt-1" />
        </div>
      </div>

      <!---<div class="vx-row mb-6">
        <div class="vx-col w-full ">
          <vs-textarea v-model="dataLocal.description" :counter-danger.sync="lab_description_counter"
                       counter="400" height="100px"
                       label="Lab Description"/>
        </div>
      </div>--->

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mb-2 mr-2" color="success" type="filled" @click="lab_update_data">Update Lab </vs-button>
          <vs-button class="mb-2 ml-2" color="warning" type="border" @click="reset_data">Reset </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import axios from 'axios';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      lab_description_counter: null,

      pools: [],

      status_options: ['active', 'hidden', 'disabled'],

      difficulties: ['unrated', 'simple', 'advanced', 'expert'],
    };
  },
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    lab_update_data() {
      this.$emit('update_lab_data', this.dataLocal);
    },
    reset_data() {
      this.dataLocal = JSON.parse(JSON.stringify(this.data));
    },
    get_pools() {
      this.$httpLabs
        .get('labs/pools')
        .then((response) => {
          this.pools = response.data.rows.map((obj) => obj.id);
        })
        .catch((error) => {});
    },
  },
  created() {
    this.get_pools();
  },
};
</script>

<style scoped></style>
